import React, {useContext, useEffect, useState} from 'react';
import Sidebar from "../components/Sidebar";
import {Box} from '@mui/material';
import Grid from '@mui/material/Grid2';
import Typography from "@mui/material/Typography";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {csCZ as datePickersCsCZ} from "@mui/x-date-pickers/locales";
import {csCZ as dataGridCsCZ} from "@mui/x-data-grid/locales";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import CircularProgress from '@mui/material/CircularProgress';
import {Link} from "react-router-dom";
import {GlobalDataContext} from "../components/GlobalDataProvider";
import {DataGridPro, gridClasses} from "@mui/x-data-grid-pro";
import {groupsAndActivitiesColumns, groupsAndActivitiesGroupingModel} from "../data/DatatableColumns";
import {groupsRows} from "../data/DatatableRows";
import {RefreshButton} from "../components/RefreshButton";
import {convertDateToMMYYDayjs, convertMMYYToDate} from "../utils/Coverter";
import {generateMonthArray, generateThisYearFirstMMYY, generateThisYearLastMMYY} from "../utils/ValueGenerator";
import dayjs from "dayjs";
import {CustomToolbar, useFilteredColumns} from "../components/CustomToolbar";

export default function Groups() {
  const {groupsAndActivitiesCompanies} = useContext(GlobalDataContext);
  const {updateMessage} = useContext(GlobalDataContext);
  const [businessMonthFrom, setBusinessMonthFrom] = useState(generateThisYearFirstMMYY() || null);
  const [businessMonthTo, setBusinessMonthTo] = useState(generateThisYearLastMMYY() || null);
  const [dateError, setDateError] = useState("");
  const [months, setMonths] = useState([]);
  const columns = useFilteredColumns(() => groupsAndActivitiesColumns(months));
  const getTreeDataPath = (row) => row.hierarchy;

  useEffect(() => {
    if (groupsAndActivitiesCompanies && groupsAndActivitiesCompanies.length > 0) {
      setMonths(generateMonthArray(businessMonthFrom, businessMonthTo));
    }
  }, [businessMonthFrom, businessMonthTo, groupsAndActivitiesCompanies]);

  const groupingColDef = {
    headerName: 'Skupina',
    minWidth: 260,
    hideDescendantCount: true,
  };

  const handleDateFromChange = (newValue) => {
    if (!newValue) {
      setBusinessMonthFrom(null);
      return
    }
    setBusinessMonthFrom(convertDateToMMYYDayjs(newValue.toString()));
    const dayjsFrom = dayjs(newValue);
    const dayjsTo = convertMMYYToDate(businessMonthTo);
    if (dayjsTo.isBefore(dayjsFrom)) {
      setDateError("Datum od musí být před datumem do!");
    } else {
      setDateError(null);
    }
  };
  const handleDateToChange = (newValue) => {
    if (!newValue) {
      setBusinessMonthTo(null);
      return;
    }
    setBusinessMonthTo(convertDateToMMYYDayjs(newValue.toString()));
    const dayjsFrom = convertMMYYToDate(businessMonthFrom);
    const dayjsTo = dayjs(newValue);
    if (dayjsTo.isBefore(dayjsFrom)) {
      setDateError("Datum od musí být před datumem do!");
    } else {
      setDateError(null);
    }
  };


  return (
    <Sidebar>
      <Grid container justifyContent="start">
        <Grid size={6}>
          <Typography variant="h5" fontWeight={600}>Data podle skupin</Typography>
        </Grid>
        <Grid size={6} textAlign="right" sx={{height: '200%'}}>
          <Typography variant="text">
            {updateMessage}
          </Typography>
        </Grid>
        <Grid size={6}>
          <Typography variant="h7" fontWeight={400}><Link to="/home"
                                                          style={{
                                                            textDecoration: 'none',
                                                            color: 'black'
                                                          }}>NOHO</Link> ->
            Skupina </Typography>
        </Grid>
        <Grid size={6} textAlign="right">
          <RefreshButton/>
        </Grid>
      </Grid>
      <Box sx={{width: "100%", marginTop: "10px", backgroundColor: '#f5f5f5', padding: 2, marginBottom: 2}}>
        <Grid container>
          <Grid size={12}>
            <LocalizationProvider adapterLocale="cs" dateAdapter={AdapterDayjs}
                                  localeText={datePickersCsCZ.components.MuiLocalizationProvider.defaultProps.localeText}>
              <DatePicker
                label="Od"
                views={['year', 'month']}
                value={convertMMYYToDate(businessMonthFrom)}
                maxDate={convertMMYYToDate(businessMonthTo)}
                onChange={handleDateFromChange}
                slotProps={{
                  textField: {
                    onFocus: () => setBusinessMonthFrom(null),
                  },
                }}
                sx={{
                  width: "220px",
                  marginRight: "15px",
                  backgroundColor: "white",
                  borderRadius: "4px",
                }}
              />
              <DatePicker
                label="Do"
                views={['year', 'month']}
                value={convertMMYYToDate(businessMonthTo)}
                minDate={convertMMYYToDate(businessMonthFrom)}
                onChange={handleDateToChange}
                slotProps={{
                  textField: {
                    onFocus: () => setBusinessMonthTo(null),
                  },
                }}
                sx={{width: "220px", marginRight: "15px", backgroundColor: "white", borderRadius: "4px"}}
              />
            </LocalizationProvider>
          </Grid>
          <Grid size={12} p={0.5} pb={0}>
            <Typography variant="text" sx={{ color: 'red'}}>
              {dateError}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      {
        groupsAndActivitiesCompanies && groupsAndActivitiesCompanies.length > 0 && months.length > 0 ? (
          <DataGridPro
            treeData
            rows={groupsRows(groupsAndActivitiesCompanies, months)}
            columns={columns}
            disableChildrenFiltering
            initialState={{
              pinnedColumns: {
                left: ["__tree_data_group__"],
                right: ['sum_assumptions', 'sum_reality', 'total_assumption_values', 'total_values'],
              },
            }}
            getTreeDataPath={getTreeDataPath}
            hideFooter
            disableColumnReorder
            localeText={dataGridCsCZ.components.MuiDataGrid.defaultProps.localeText}
            groupingColDef={groupingColDef}
            columnGroupingModel={groupsAndActivitiesGroupingModel(months)}
            slots={{toolbar: CustomToolbar}}
            getCellClassName={(params) => {
              let classNames = '';

              // Add 'negative' class if the value is negative
              if (params.value < 0) {
                classNames += 'negative ';
              }

              // Add 'spendings-total' class if specific conditions match
              if (params.colDef.headerName.includes("Skutečnost") && (params.rowNode.groupingKey.includes("Celkem") || params.rowNode.parent.includes("Celkem"))) {
                classNames += 'spendings-total ';
              }

              // Add 'spendings-cashflow' class if in the 'Skutečnost' column and 'cashFlow' id
              if (params.colDef.headerName.includes("Skutečnost") && params.id.includes("cashFlow")) {
                classNames += 'spendings-cashflow ';
              }

              // Add 'spendings' class for 'Skutečnost' column
              if (params.colDef.headerName.includes("Skutečnost")) {
                classNames += 'spendings ';
              }

              // Add 'total' class for rows containing 'Celkem'
              if (params.rowNode.groupingKey.includes("Celkem") || params.rowNode.parent.includes("Celkem")) {
                classNames += 'total ';
              }

              // Add 'cashflow' class for rows with 'cashFlow' id
              if (params.id.includes("cashFlow")) {
                classNames += 'cashflow ';
              }

              return classNames.trim(); // Remove any trailing spaces
            }}
            sx={{
              height: "78vh",
              '&.MuiDataGrid-root': {
                borderRadius: '0px',
                borderLeft: 'none',
                borderRight: 'none',
              },
              '& .MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold', overflow: 'visible',
              },
              '& .spendings': {
                borderRight: "1px solid rgba(224, 224, 224, 1)",
              },
              '& .total': {
                fontWeight: 'bold',
              },
              '& .cashflow': {
                fontWeight: 'bold',
                backgroundColor: '#f5f5f5',
              },
              '& .spendings-cashflow': {
                fontWeight: 'bold',
                borderRight: "1px solid rgba(224, 224, 224, 1)",
                backgroundColor: '#f5f5f5',
              },
              '& .spendings-total': {
                borderRight: "1px solid rgba(224, 224, 224, 1)",
                fontWeight: 'bold',
              },
              // Style for pinned rows
              [`.${gridClasses.cell}.pinned-row`]: {
                fontWeight: 'bold',
                backgroundColor: '#f5f5f5',
              },
              [`.${gridClasses.cell}.pinned-row_spendings`]: {
                fontWeight: 'bold',
                backgroundColor: '#f5f5f5',
                borderRight: "1px solid rgba(224, 224, 224, 1)",
              },
              [`.${gridClasses.cell}.spendings`]: {
                borderRight: "1px solid rgba(224, 224, 224, 1)",
              },
              // Style for negative numbers
              '& .MuiDataGrid-cell.negative': {
                color: 'red',
              },
            }}
          />


        ) : (
          <CircularProgress sx={{position: 'absolute', top: '50%', left: '50%'}}/>
        )
      }
    </Sidebar>
  );
}
