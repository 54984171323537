import {
  generateUserValueListFullValueFirstMonth,
  generateUserValueListFullValueLastMonth, generateUserValueListSplitFirstLastMonth,
  generateUserValueListWithDistribution
} from "./ValueGenerator";

export const arraysAreEqual = (arr1, arr2) => {
  if (arr1.length !== arr2.length) {
    return false;
  }
  return arr1.every(item => arr2.includes(item)) && arr2.every(item => arr1.includes(item));
};

export const montAndYearAreEqual = (date1, date2) => {
  const d1 = new Date(date1);
  const d2 = new Date(date2);

  return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth();
};
export const determineDistributionType = (userValueList, phaseList, startDate, endDate, costAssumption, scope) => {
  const generators = {
    evenly: generateUserValueListWithDistribution,
    firstMonth: generateUserValueListFullValueFirstMonth,
    lastMonth: generateUserValueListFullValueLastMonth,
    firstAndLastMonth: generateUserValueListSplitFirstLastMonth,
  };

  for (const [type, generatorFunction] of Object.entries(generators)) {
    let inputContract = {phaseList, startDate, endDate, costAssumption, scope};
    const expectedArray = generatorFunction(inputContract);

    const isMatch = userValueList.every((inputPhase, phaseIndex) => {
      const expectedPhase = expectedArray[phaseIndex];

      if (inputPhase.label !== expectedPhase.label) {
        return false;
      }

      const scopeMatches = inputPhase.businessMonthScopeList.every((inputScope, index) => {
        const expectedScope = expectedPhase.businessMonthScopeList[index];
        return (
          inputScope.businessMonth === expectedScope.businessMonth &&
          inputScope.value.toFixed(0) === expectedScope.value.toFixed(0)
        );
      });

      const assumptionMatches = inputPhase.businessMonthAssumptionList.every((inputAssumption, index) => {
        const expectedAssumption = expectedPhase.businessMonthAssumptionList[index];
        return (
          inputAssumption.businessMonth === expectedAssumption.businessMonth &&
          inputAssumption.value.toFixed(0) === expectedAssumption.value.toFixed(0)
        );
      });

      return scopeMatches && assumptionMatches;
    });

    if (isMatch) {
      return type;
    }
  }

  return "custom";
}


