import dayjs from "dayjs";
import {convertDateToMMYY} from "./Coverter";

export function generateMonthArray(from, to) {
  if (!from || !to) {
    return [];
  }
  const fromMonth = parseInt(from.substring(0, 2), 10);
  const fromYear = parseInt(`20${from.substring(2, 4)}`, 10);
  const toMonth = parseInt(to.substring(0, 2), 10);
  const toYear = parseInt(`20${to.substring(2, 4)}`, 10);

  let startDate = dayjs(new Date(fromYear, fromMonth - 1, 1));
  const endDate = dayjs(new Date(toYear, toMonth - 1, 1));

  const months = [];

  while (startDate.isBefore(endDate) || startDate.isSame(endDate, 'month')) {
    const month = startDate.format('MMYY');
    months.push(month);
    startDate = startDate.add(1, 'month');
  }

  return months;
}

export function generateEmptyUserValueList(phaseList, businessMonths) {
  return phaseList.map(phase => ({
    label: phase,
    businessMonthScopeList: businessMonths.map(month => ({
      businessMonth: month,
      value: 0
    })),
    businessMonthAssumptionList: businessMonths.map(month => ({
      businessMonth: month,
      value: 0
    }))
  }));
  }

export function generateUserValueListWithDistribution(contract) {
  const {phaseList, startDate, endDate, costAssumption, scope} = contract;
  const businessMonths = generateMonthArray(convertDateToMMYY(startDate), convertDateToMMYY(endDate));
  const totalPhases = phaseList.length;
  const totalMonths = businessMonths.length;
  const totalPhaseMonthCount = totalPhases * totalMonths;

  const assumptionPerPhaseMonth = costAssumption / totalPhaseMonthCount;
  const scopePerPhaseMonth = scope / totalPhaseMonthCount;

  return phaseList.map((phase) => ({
    label: phase,
    businessMonthScopeList: businessMonths.map((month) => ({
      businessMonth: month,
      value: scopePerPhaseMonth,
    })),
    businessMonthAssumptionList: businessMonths.map((month) => ({
      businessMonth: month,
      value: assumptionPerPhaseMonth,
    })),
  }));
}

export function generateUserValueListFullValueFirstMonth(contract) {
  const {phaseList, startDate, endDate, costAssumption, scope} = contract;
  const businessMonths = generateMonthArray(convertDateToMMYY(startDate), convertDateToMMYY(endDate));
  const totalPhases = phaseList.length;
  const assumptionPerPhase = costAssumption / totalPhases;
  const scopePerPhase = scope / totalPhases;

  return phaseList.map((phase) => ({
    label: phase,
    businessMonthScopeList: businessMonths.map((month, index) => ({
      businessMonth: month,
      value: index === 0 ? scopePerPhase : 0,
    })),
    businessMonthAssumptionList: businessMonths.map((month, index) => ({
      businessMonth: month,
      value: index === 0 ? assumptionPerPhase : 0,
    })),
  }));
}

export function generateUserValueListFullValueLastMonth(contract) {
  const {phaseList, startDate, endDate, costAssumption, scope} = contract;
  const businessMonths = generateMonthArray(convertDateToMMYY(startDate), convertDateToMMYY(endDate));
  const totalPhases = phaseList.length;
  const assumptionPerPhase = costAssumption / totalPhases;
  const scopePerPhase = scope / totalPhases;

  return phaseList.map((phase) => ({
    label: phase,
    businessMonthScopeList: businessMonths.map((month, index) => ({
      businessMonth: month,
      value: index === businessMonths.length - 1 ? scopePerPhase : 0,
    })),
    businessMonthAssumptionList: businessMonths.map((month, index) => ({
      businessMonth: month,
      value: index === businessMonths.length - 1 ? assumptionPerPhase : 0,
    })),
  }));
}

export function generateUserValueListSplitFirstLastMonth(contract) {
  const {phaseList, startDate, endDate, costAssumption, scope} = contract;
  const businessMonths = generateMonthArray(convertDateToMMYY(startDate), convertDateToMMYY(endDate));
  const totalPhases = phaseList.length;
  const assumptionPerPhase = costAssumption / totalPhases;
  const scopePerPhase = scope / totalPhases;

  return phaseList.map((phase) => ({
    label: phase,
    businessMonthScopeList: businessMonths.map((month, index) => ({
      businessMonth: month,
      value:
        index === 0
          ? scopePerPhase / 2
          : index === businessMonths.length - 1
            ? scopePerPhase / 2
            : 0,
    })),
    businessMonthAssumptionList: businessMonths.map((month, index) => ({
      businessMonth: month,
      value:
        index === 0
          ? assumptionPerPhase / 2
          : index === businessMonths.length - 1
            ? assumptionPerPhase / 2
            : 0,
    })),
  }));
}

export function adjustUserValueList(oldUserValueList, contract) {
  const { phaseList, startDate, endDate } = contract;
  const businessMonths = generateMonthArray(convertDateToMMYY(startDate), convertDateToMMYY(endDate));

  return phaseList.map((phase) => {

    const existingPhase = oldUserValueList.find((item) => item.label === phase);

    const businessMonthScopeList = businessMonths.map((month) => {
      const existingEntry = existingPhase?.businessMonthScopeList.find((entry) => entry.businessMonth === month);
      return {
        businessMonth: month,
        value: existingEntry ? existingEntry.value : 0,
      };
    });

    const businessMonthAssumptionList = businessMonths.map((month) => {
      const existingEntry = existingPhase?.businessMonthAssumptionList.find((entry) => entry.businessMonth === month);
      return {
        businessMonth: month,
        value: existingEntry ? existingEntry.value : 0,
      };
    });

    return {
      label: phase,
      businessMonthScopeList,
      businessMonthAssumptionList,
    };
  });
}
export function generateThisYearFirstMMYY() {
  const currentYear = new Date().getFullYear() ;
  const firstDayOfYear = new Date(currentYear, 0, 1);
  return convertDateToMMYY(firstDayOfYear.toString());
}

export function generateThisYearLastMMYY() {
  const currentYear = new Date().getFullYear();
  const lastDayOfYear = new Date(currentYear, 11, 31);
  return convertDateToMMYY(lastDayOfYear.toString());
}

