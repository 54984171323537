import {useGridPrivateApiContext} from "@mui/x-data-grid-pro/hooks/utils/useGridPrivateApiContext";
import {GRID_ROOT_GROUP_ID, gridRowTreeSelector, GridToolbarContainer, useGridSelector} from "@mui/x-data-grid-pro";
import React from "react";
import { Button} from "@mui/material";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import {GridToolbarQuickFilter} from "@mui/x-data-grid";

export function CustomToolbar( ) {
  const apiRef = useGridPrivateApiContext();
  const tree = useGridSelector(apiRef, gridRowTreeSelector);

  const expandAll = React.useCallback(() => {
    const newTree = { ...tree };
    const traverse = (id) => {
      const node = tree[id];
      if (node.type === 'group') {
        const newNode = { ...node, childrenExpanded: true };
        newTree[id] = newNode;
        node.children.forEach((child) => traverse(child));
      }
    };

    traverse(GRID_ROOT_GROUP_ID);
    apiRef.current.setState((state) => ({
      ...state,
      rows: {
        ...state.rows,
        tree: newTree,
      },
    }));
    apiRef.current.publishEvent('rowExpansionChange', newTree[GRID_ROOT_GROUP_ID]);
  }, [tree]);

  const collapseAll = React.useCallback(() => {
    const newTree = { ...tree };
    const traverse = (id) => {
      const node = tree[id];
      if (node.type === 'group') {
        const newNode = { ...node, childrenExpanded: false };
        newTree[id] = newNode;
        node.children.forEach((child) => traverse(child));
      }
    };

    traverse(GRID_ROOT_GROUP_ID);
    apiRef.current.setState((state) => ({
      ...state,
      rows: {
        ...state.rows,
        tree: newTree,
      },
    }));
    apiRef.current.publishEvent('rowExpansionChange', newTree[GRID_ROOT_GROUP_ID]);
  }, [tree]);

  return (
    <GridToolbarContainer sx={{borderBottom: "1px solid #E0E0E0", p: 0.5}}>
        <Button endIcon={<KeyboardDoubleArrowDownIcon/>} onClick={expandAll}>Rozbalit</Button>
        <Button endIcon={<KeyboardDoubleArrowUpIcon/>} onClick={collapseAll}>Sbalit</Button>
        <GridToolbarQuickFilter sx={{ml: 1}}/>
    </GridToolbarContainer>
  );
}

const hiearchicFilter = (value) => {
  return (cellValue, filter) => {
    const hierarchy = filter.hierarchy || [];
    return hierarchy.some(item => item.toLowerCase().includes(value.toLowerCase()))
      ||
      cellValue?.toString().toLowerCase().includes(value.toString().toLowerCase());
  };
};

export const useFilteredColumns = (getColumns) => {
  return React.useMemo(
    () =>
      getColumns().map((column) => {
        if (column.type === 'date') {
          console.log(column);
          return {
            ...column,
            getApplyQuickFilterFn: undefined,
          };
        }else{
          return {
            ...column,
            getApplyQuickFilterFn: hiearchicFilter,
          }
        }
      }),
    [getColumns]
  );
};