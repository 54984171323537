import React, {useContext, useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import {
  Button,
  FormControl, IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Switch,
  Tab,
  Tabs,
  TextField,
  Typography
} from "@mui/material";
import Grid from '@mui/material/Grid2';
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import axios from "axios";
import toast from "react-hot-toast";
import {useAuthHeader} from "react-auth-kit";
import DeleteIcon from '@mui/icons-material/Delete';
import LoadingButton from "@mui/lab/LoadingButton";
import {NumericFormat} from 'react-number-format';
import {useNavigate} from "react-router-dom";
import {toastWithError} from "../../ToastWithError";
import {GlobalDataContext} from "../../GlobalDataProvider";
import {formatAmount, formatDateAddSlash} from "../../../utils/Formater";
import {
  generateMonthArray,
  generateEmptyUserValueList,
  generateUserValueListWithDistribution,
  generateUserValueListFullValueFirstMonth,
  generateUserValueListFullValueLastMonth, generateUserValueListSplitFirstLastMonth
} from "../../../utils/ValueGenerator";
import FinancialInfo from "./FinancialInfo";
import {convertDateToMMYY} from "../../../utils/Coverter";
import FinancialInvoices from "./FinancialInvoices";
import {determineDistributionType} from "../../../utils/Comparator";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95%', // Default width for both PC and mobile
  overflowY: 'auto', // Enable vertical scrolling
  boxShadow: 24,
  borderRadius: "10px",
  bgcolor: 'background.paper',
};

const FinancialPlan = ({
                         opened,
                         handleClose: close,
                         selectedContract,
                         updateContract,
                         company
                       }) => {
  const {setUpdateMessage} = useContext(GlobalDataContext);
  const {getUpdatedDataTime} = useContext(GlobalDataContext);
  const [saving, setSaving] = useState(false);
  const [showInfo, setShowInfo] = useState(true);
  const [userValueList, setUserValueList] = useState([]);
  const [template, setTemplate] = React.useState('');
  const [tab, setTab] = useState(0);
  const getAuthHeader = useAuthHeader();
  const navigate = useNavigate();


  useEffect(() => {
    if (opened) {
      fetchUserValues(selectedContract);
    } else {
      setUserValueList([]);
      setTemplate('');
    }
  }, [opened]);

  useEffect(() => {
    if (selectedContract) {
      switch (template) {
        case 'evenly':
          setUserValueList(generateUserValueListWithDistribution(selectedContract));
          break;
        case 'firstMonth':
          setUserValueList(generateUserValueListFullValueFirstMonth(selectedContract));
          break;
        case 'lastMonth':
          setUserValueList(generateUserValueListFullValueLastMonth(selectedContract));
          break;
        case 'firstAndLastMonth':
          setUserValueList(generateUserValueListSplitFirstLastMonth(selectedContract));
          break;
        default:
          break;
      }
    }
  }, [template]);

  useEffect(() => {
    if (selectedContract && userValueList.length > 0) {
      const newTemplate = determineDistributionType(userValueList, selectedContract.phaseList, selectedContract.startDate, selectedContract.endDate, selectedContract.costAssumption, selectedContract.scope);
      setTemplate(newTemplate);
    }
  }, [userValueList]);

  function fetchUserValues(selectedContract) {
    const {startDate, endDate, phaseList, userValueList, costAssumption, scope} = selectedContract;

    const businessMonths = generateMonthArray(convertDateToMMYY(startDate), convertDateToMMYY(endDate));

    const defaultValues = generateEmptyUserValueList(phaseList, businessMonths);

    userValueList.forEach(userValue => {
      const targetPhase = defaultValues.find(phase => phase.label === userValue.label);
      if (targetPhase) {
        ['businessMonthScopeList', 'businessMonthAssumptionList'].forEach(key => {
          userValue[key]?.forEach(userValueEntry => {
            const targetMonth = targetPhase[key].find(
              defaultEntry => defaultEntry.businessMonth === userValueEntry.businessMonth
            );
            if (targetMonth) {
              targetMonth.value = userValueEntry.value;
            }
          });
        });
      }
    });
    const template = determineDistributionType(defaultValues, phaseList, startDate, endDate, costAssumption, scope);
    setTemplate(template);
    setUserValueList(defaultValues);

  }

  const calculateActualPaymentMonthSum = (businessMonth, contractCode) => {
    return company.receivedInvoiceList
      .filter((invoice) => invoice.contract === contractCode) // Match by contractCode
      .reduce((sum, invoice) => {
        // Sum up payment amounts for the specified businessMonth
        const monthlyPayments = invoice.paymentList
          ?.filter((payment) => convertDateToMMYY(payment.paymentDate) === businessMonth) // Match the month
          .reduce((paymentSum, payment) => paymentSum + payment.paymentAmount, 0) || 0;

        return sum + monthlyPayments;
      }, 0);
  };

  const handleChangeAssumption = (values, sourceInfo, month, phase) => {
    if (sourceInfo.source === "prop") return;
    const newAssumptionValue = values.floatValue || 0;

    setUserValueList((prevList) =>
      prevList.map((userValue) => {
        if (userValue.label === phase) {
          return {
            ...userValue,
            businessMonthAssumptionList: userValue.businessMonthAssumptionList.map((item) =>
              item.businessMonth === month
                ? {...item, value: newAssumptionValue}
                : item
            ),
          };
        }
        return userValue;
      })
    );
    setTemplate("custom")
  };

  const handleChangeScope = (values, sourceInfo, month, phase) => {
    if (sourceInfo.source === "prop") return;
    const newScopeValue = values.floatValue || 0;

    setUserValueList((prevList) =>
      prevList.map((userValue) => {
        if (userValue.label === phase) {
          return {
            ...userValue,
            businessMonthScopeList: userValue.businessMonthScopeList.map((item) =>
              item.businessMonth === month ? {...item, value: newScopeValue} : item
            ),
          };
        }
        return userValue;
      })
    );
    setTemplate("custom")
  };

  const handlePopUpSubmit = async () => {
    try {
      setSaving(true);
      const token = getAuthHeader();
      if (!token) {
        setSaving(false);
        navigate("/");
        toast.error("Přihlášení vypršelo!");
        return
      }
      const updatingDataset = await axios.get(
          process.env.REACT_APP_FLEXIOVERVIEW_API_URL + `/dataset/get`,
        {headers: {Authorization: token}}
      );
      if (updatingDataset.data.updating) {
        setSaving(false);
        toast.error(`Právě aktualizuji data. Zkuste to znovu později!`);
        setUpdateMessage(updatingDataset.data.message);
        getUpdatedDataTime();
        return
      }
      const contract = company.contractList.find(
        (contract) => contract.code === selectedContract.code
      );

      const response = (await axios.post(
          process.env.REACT_APP_FLEXIOVERVIEW_API_URL + "/contract/updateSettings",
        {
          costCenterCode: contract.costCenter,
          contractCode: selectedContract.code,
          userValueList: userValueList
        },
        {headers: {Authorization: token}}
      )).data.contract;

      toast.success("Finanční plán byl aktualizován!");
      handleClose();
      updateContract(response.contractCode, response.userValueList, company.companyDetail.code, response.costCenterCode);
      setSaving(false);
    } catch (error) {
      toastWithError(error);
      toast.error("Finanční plán se nepodařilo aktualizovat!");
      console.error('Error updating time interval:', error);
      setSaving(false);
    }
  };

  const handleClearAssumption = () => {
    userValueList.forEach(userValue => {
      userValue.businessMonthAssumptionList.forEach(assumption => {
        assumption.value = 0;
      });
    });

    setUserValueList([...userValueList]);
    setTemplate("custom");
  };

  const handleClearScope = () => {
    userValueList.forEach(userValue => {
      userValue.businessMonthScopeList.forEach(scope => {
        scope.value = 0;
      });
    });

    setUserValueList([...userValueList]);
    setTemplate("custom");
  };

  const handleClose = () => {
    close();
    setTemplate("custom");
    setTab(0);
  }


  return (
    selectedContract &&
    <Modal open={opened} onClose={handleClose} aria-labelledby="user-edit-modal" aria-describedby="user-edit-form">
      <Box sx={{...style, maxWidth: tab === 1 ? "100%" : "90vh"}} component="form" onSubmit={handlePopUpSubmit}>
        {/* Sticky header section */}
        <Box sx={{position: 'sticky', top: 0, backgroundColor: '#ffffff', zIndex: 1}}>
          <Grid container>
            <Grid item size={6}>
              <Typography variant="h5" sx={{p: 3, paddingBottom: "10px", fontWeight: 'bold'}}>
                Finanční plán
              </Typography>
            </Grid>
            <Grid item size={6} sx={{p: 2, paddingBottom: 0, display: 'flex', justifyContent: 'flex-end'}}>
              <Typography sx={{display: tab === 1 ? 'none' : 'flex', justifyContent: "center", alignItems: "center"}}>
                Zobrazit informace
              </Typography>
              <Switch
                sx={{display: tab === 1 ? 'none' : 'flex'}}
                checked={showInfo}
                onChange={() => setShowInfo(!showInfo)}
              />
              <IconButton onClick={handleClose} >
                <CloseIcon/>
              </IconButton>
            </Grid>
          </Grid>

          <Tabs value={tab} sx={{paddingLeft: 3, paddingRight: 3, paddingBottom: 0.5}}
                onChange={(e, newValue) => setTab(newValue)}>
            <Tab label="Finanční plán"/>
            <Tab label="Faktury"/>
          </Tabs>
        </Box>

        <Box sx={{
          p: tab === 1 ? 0 : 3,
          paddingTop: 0,
          backgroundColor: '#f8f8f8',
          maxHeight: '75vh',
          overflow: 'auto',
          position: 'relative'
        }}>
          <Box hidden={tab !== 0} sx={{paddingTop: -1}}>
            <FinancialInfo showInfo={showInfo} selectedContract={selectedContract} company={company}
                           userValueList={userValueList} setUserValueList={setUserValueList} template={template}
                           setTemplate={setTemplate}/>

            {/* Sticky grid within scrollable area */}
            <Grid container sx={{
              display: showInfo ? 'none' : 'flex',
              height: '100%',
              paddingBottom: '13px',
              position: 'sticky',
              top: -0.5,
              backgroundColor: '#f8f8f8',
              zIndex: 1,
              marginTop: -1,
              paddingTop: 2,
            }}>
              <Grid size={2}>
                <Typography variant="body1" sx={{color: 'inherit', paddingTop: 0.5, paddingBottom: 2}}>
                  <strong>Šablona: </strong>
                </Typography>
              </Grid>
              <Grid size={10} sx={{color: 'inherit', paddingBottom: 2}}>
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    size="small"
                    value={template}
                    onChange={(e) => setTemplate(e.target.value)}
                    sx={{maxWidth: '200px'}}
                  >
                    <MenuItem value="evenly">Rovnoměrně</MenuItem>
                    <MenuItem value="lastMonth">Na konci</MenuItem>
                    <MenuItem value="firstMonth">Na začátku</MenuItem>
                    <MenuItem value="firstAndLastMonth">Na konci a začátku</MenuItem>
                    <MenuItem value="custom">Vlastní</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid size={1.5}>
                <Typography variant="body2" fontWeight="bold">
                  Fáze
                </Typography>
              </Grid>
              <Grid size={1.5}>
                <Typography variant="body2" fontWeight="bold">
                  Měsíc
                </Typography>
              </Grid>
              <Grid size={3.25}>
                <div style={{alignItems: 'center', display: 'inline-flex', width: '100%'}}>
                  <Typography variant="body2" fontWeight="bold" marginLeft={1}>
                    Plán
                  </Typography>
                  <DeleteIcon onClick={() => handleClearAssumption()}
                              sx={{cursor: 'pointer', fontSize: "1.2rem", marginLeft: 1}}/>
                </div>
              </Grid>
              <Grid size={3.25}>
                <div style={{alignItems: 'center', display: 'inline-flex', width: '100%'}}>
                  <Typography variant="body2" fontWeight="bold" marginLeft={1.5}>
                    Smlouva
                  </Typography>
                  <DeleteIcon onClick={() => handleClearScope()}
                              sx={{cursor: 'pointer', fontSize: "1.2rem", marginLeft: 1}}/>
                </div>
              </Grid>
              <Grid size={2.5}>
                <Typography variant="body2" fontWeight="bold" marginLeft={1.5}>
                  Realizováno
                </Typography>
              </Grid>
            </Grid>
            {userValueList.length === 0 && (
              <Grid size={12} sx={{paddingTop: 1, display: 'flex', justifyContent: 'center'}}>
                <Typography variant="h7" sx={{textAlign: 'center'}}>
                  Načítám...
                </Typography>
              </Grid>
            )}
            <Grid container spacing={2} alignItems="center" justifyContent="center" paddingTop={1}>
              {userValueList.length > 0 && userValueList.map((userValue, index) => userValue.businessMonthScopeList.map((scope, idx) => {
                const assumption = userValue.businessMonthAssumptionList[idx];
                return (<React.Fragment key={`${index}-${idx}`}>
                  <Grid size={1.5}>
                    <Typography variant="body2">{userValue.label}</Typography>
                  </Grid>
                  <Grid size={1.5}>
                    <Typography
                      variant="body2">{formatDateAddSlash(scope.businessMonth)}</Typography>
                  </Grid>
                  <Grid size={3.25}>
                    <NumericFormat
                      customInput={TextField} // Use MUI TextField as the input component
                      value={assumption.value}
                      onValueChange={(values, sourceInfo) => handleChangeAssumption(values, sourceInfo, assumption.businessMonth, userValue.label)}// Update the value when the user types
                      onFocus={(e) => e.target.select()} // Select the text when the input is focused
                      thousandSeparator=" " // Format with commas for thousands separator
                      decimalSeparator="," // Use period for decimal separator
                      decimalScale={0} // Limit to 2 decimal places
                      allowNegative={false} // Disable negative values
                      isNumericString={false} // Make sure it's treated as a numeric string
                      size="small"
                      InputProps={{
                        endAdornment: <InputAdornment position='start'>Kč</InputAdornment>
                      }}
                    />
                  </Grid>
                  <Grid size={3.25}>
                    <NumericFormat
                      customInput={TextField} // Use MUI TextField as the input component
                      value={scope.value.toFixed(0)}
                      onValueChange={(values, sourceInfo) => handleChangeScope(values, sourceInfo, scope.businessMonth, userValue.label)}// Update the value when the user types
                      onFocus={(e) => e.target.select()} // Select the text when the input is focused
                      thousandSeparator=" " // Format with commas for thousands separator
                      decimalSeparator="," // Use period for decimal separator
                      decimalScale={4} // Limit to 2 decimal places
                      allowNegative={false} // Disable negative values
                      isNumericString={true} // Make sure it's treated as a numeric string
                      size="small"
                      InputProps={{
                        endAdornment: <InputAdornment position='start'>Kč</InputAdornment>
                      }}
                    />
                  </Grid>
                  <Grid size={2.5}>
                    <Typography variant="body2" sx={{
                      color: (calculateActualPaymentMonthSum(
                        scope.businessMonth,
                        selectedContract.code,
                      )) >= 0 ? 'inherit' : 'red'
                    }}>{formatAmount(calculateActualPaymentMonthSum(
                      scope.businessMonth,
                      selectedContract.code,
                    ))}</Typography>
                  </Grid>
                </React.Fragment>);
              }))}
            </Grid>
          </Box>
          <Box hidden={tab !== 1} sx={{marginTop: -0.5}}>
            <FinancialInvoices company={company} selectedContract={selectedContract}/>
          </Box>
        </Box>

        <Box sx={{position: 'sticky', bottom: 0, backgroundColor: '#ffffff'}}>
          <Grid container sx={{p: 3}}>
            <Grid size={6}/>
            <Grid size={6} sx={{display: tab === 0 ? 'flex' : 'none', justifyContent: 'flex-end', gap: 2}}>
              <Button variant="outlined" disabled={saving} onClick={handleClose} startIcon={<CloseIcon/>}>
                Zrušit
              </Button>
              <LoadingButton variant="contained" loading={saving} loadingPosition="end"
                             onClick={handlePopUpSubmit}
                             endIcon={<SaveIcon/>}>
                Uložit
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};

export default FinancialPlan;
