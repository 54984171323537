import * as Yup from "yup";
import dayjs from "dayjs";
import {removeTimezone} from "./Formater";

export const userCreateSchema = Yup.object({
  firstname: Yup.string()
    .required('Jméno je povinné'),
  lastname: Yup.string()
    .required('Příjmení je povinné'),
  username: Yup.string()
    .email('Neplatný email')
    .required('E-mail je povinný'),
  password: Yup.string()
    .required('Heslo je povinné'),
  confirmPassword: Yup.string()
    .required('Potvrzení hesla je povinné')
    .oneOf([Yup.ref('password'), null], 'Hesla se musí shodovat'),
  role: Yup.string()
    .required('Role je povinná'),
  active: Yup.boolean()
    .required('Aktivita je povinná'),
});

export const userEditSchema = Yup.object({
  firstname: Yup.string()
    .required('Jméno je povinné'),
  lastname: Yup.string()
    .required('Příjmení je povinné'),
  username: Yup.string()
    .email('Neplatný email')
    .required('E-mail je povinný'),
  password: Yup.string(),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Hesla se musí shodovat'),
  role: Yup.string()
    .required('Role je povinná'),
  active: Yup.boolean()
    .required('Aktivita je povinná'),
});

export const contractSchema = Yup.object({
    code: Yup.string()
        .required('Kód je poviný')
        .test('no-lowercase', 'Kód nesmí obsahovat malá písmena', (value) =>
            value ? !/[a-z]/.test(value) : true
        ),
    description: Yup.string().required('Název je povinný'),
    costCenter: Yup.string().required('Středisko je povinné'),
    scope: Yup.number(),
    costAssumption: Yup.number(),
    startDate: Yup.string()
        .required('Datum od je povinné')
        .test('valid-date', 'Neplatný formát', (value) =>
            value ? dayjs(removeTimezone(value), 'YYYY-MM-DD', true).isValid() : true
        )
        .test(
            'start-before-end',
            'Datum zahájení musí být menší než datum ukončení',
            function (value) {
                const { endDate } = this.parent;
                if (!value || !endDate) return true;
                const start = dayjs(removeTimezone(value), 'YYYY-MM-DD', true);
                const end = dayjs(removeTimezone(endDate), 'YYYY-MM-DD', true);
                if (!start.isValid() || !end.isValid()) return true;
                return start.isBefore(end);
            }
        ),
    endDate: Yup.string()
        .required('Datum do je povinné')
        .test('valid-date', 'Neplatný formát', (value) =>
            value ? dayjs(removeTimezone(value), 'YYYY-MM-DD', true).isValid() : true
        )
        .test(
            'end-after-start',
            'Datum ukončení musí být větší než datum zahájení',
            function (value) {
                const { startDate } = this.parent;
                if (!value || !startDate) return true;
                const start = dayjs(removeTimezone(startDate), 'YYYY-MM-DD', true);
                const end = dayjs(removeTimezone(value), 'YYYY-MM-DD', true);
                if (!start.isValid() || !end.isValid()) return true;
                return start.isBefore(end);
            }
        ),
    status: Yup.string().nullable(),
    groupList: Yup.array().min(1, 'Skupina je povinná'),
    phaseList: Yup.array().min(1, 'Fáze jsou povinné'),
    dictionaryItemCompanyCode: Yup.string().nullable(),
});